var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FilterablePage } from "../../filterable-page/filterable-page";
import { ActivatedRoute } from "@angular/router";
var TopTracksPageComponent = /** @class */ (function (_super) {
    __extends(TopTracksPageComponent, _super);
    /**
     * TopTracksPageComponent Constructor.
     */
    function TopTracksPageComponent(route) {
        var _this = _super.call(this, ['name', 'album.name', 'album.artist.name']) || this;
        _this.route = route;
        return _this;
    }
    TopTracksPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.setItems(data.tracks);
        });
    };
    return TopTracksPageComponent;
}(FilterablePage));
export { TopTracksPageComponent };
