var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { TrackContextMenuComponent } from "../../tracks/track-context-menu/track-context-menu.component";
import { Player } from "../../player/player.service";
import { UserLibrary } from "../../users/user-library/user-library.service";
import { PlaylistService } from "../playlist.service";
import { Playlists } from "../playlists.service";
import { Lyrics } from "../../lyrics/lyrics.service";
var PlaylistTrackContextMenuComponent = /** @class */ (function (_super) {
    __extends(PlaylistTrackContextMenuComponent, _super);
    /**
     * PlaylistTrackContextMenuComponent Constructor.
     */
    function PlaylistTrackContextMenuComponent(player, userLibrary, injector, playlist, playlists, lyrics) {
        var _this = _super.call(this, player, userLibrary, injector, lyrics) || this;
        _this.player = player;
        _this.userLibrary = userLibrary;
        _this.injector = injector;
        _this.playlist = playlist;
        _this.playlists = playlists;
        _this.lyrics = lyrics;
        return _this;
    }
    /**
     * Remove track from currently active playlist.
     */
    PlaylistTrackContextMenuComponent.prototype.removeFromPlaylist = function () {
        this.playlists.removeTracks(this.data.playlistId, this.getTracks()).subscribe();
        this.contextMenu.close();
    };
    return PlaylistTrackContextMenuComponent;
}(TrackContextMenuComponent));
export { PlaylistTrackContextMenuComponent };
