var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FilterablePage } from "../../../filterable-page/filterable-page";
import { ActivatedRoute } from "@angular/router";
import { WebPlayerUrls } from "../../../web-player-urls.service";
var LibraryArtistsComponent = /** @class */ (function (_super) {
    __extends(LibraryArtistsComponent, _super);
    /**
     * LibraryArtistsComponent Constructor.
     */
    function LibraryArtistsComponent(route, urls) {
        var _this = _super.call(this, ['name']) || this;
        _this.route = route;
        _this.urls = urls;
        /**
         * Current order for library albums.
         */
        _this.order = 'Date Added';
        return _this;
    }
    LibraryArtistsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.setItems(data.artists);
        });
    };
    return LibraryArtistsComponent;
}(FilterablePage));
export { LibraryArtistsComponent };
