var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DefaultUrlSerializer } from "@angular/router";
var CustomUrlSerializer = /** @class */ (function (_super) {
    __extends(CustomUrlSerializer, _super);
    function CustomUrlSerializer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomUrlSerializer.prototype.parse = function (url) {
        url = url.replace(/\+/g, '%20');
        url = url.replace(/\(/g, '%28').replace(/\)/g, '%29');
        return _super.prototype.parse.call(this, url);
    };
    CustomUrlSerializer.prototype.serialize = function (tree) {
        return _super.prototype.serialize.call(this, tree)
            .replace(/%20/g, '+')
            .replace(/%28/g, '(')
            .replace(/%29/g, ')');
    };
    return CustomUrlSerializer;
}(DefaultUrlSerializer));
export { CustomUrlSerializer };
